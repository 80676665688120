<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 27 26"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M5.6 12.2 1 25l12.8-4.5M3.4 2.2h0m21.6 6h0M16.6 1h0M25 22.6h0M25 1l-2.7.9A3.5 3.5 0 0 0 20 5.6v0c0 1-.7 2-1.8 2h-.4c-1 0-2 .7-2.1 1.7l-.3 1.3m9.6 3.6-1-.4c-1-.4-2.2.2-2.4 1.3 0 .9-.8 1.5-1.7 1.5H19M11.8 1l.4 1c.4 1-.2 2.2-1.3 2.4-.9 0-1.5.8-1.5 1.7V7"
    />
  </svg>
</template>
